<template>
  <div class="a">{{ dataValue.output.title }}</div>
  <div class="b">{{ dataValue.output.date }} - {{ dataValue.output.from }}</div>
  <div class="c"><a :href="dataValue.output.src" target="_blank">{{ dataValue.output.src }}</a></div>
  <div class="d" v-html="dataValue.output.body"></div>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";

export default {
  name : "crawler_news_show",
  props : [ 'cnewid' ],
  setup(props, {  }) {

    let dataValue = reactive({

      input : {
        newId : props['cnewid']
      },
      output : {
        newId : 0,
        source : "",
        src : "",
        title : "",
        date : "",
        from : "",
        body : ""
      }

    })

    let allEvent = {

      httpShow : () => {
        httpRequest.post('/admin/crawler.news/show', dataValue.input)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.output.newId = res.data['newId'];
                dataValue.output.source = res.data['source'];
                dataValue.output.src = res.data['src'];
                dataValue.output.title = res.data['title'];
                dataValue.output.date = res.data['date'];
                dataValue.output.from = res.data['from'];
                dataValue.output.body = res.data['body'];
              }
            })
      },

      init : () => {
        allEvent.httpShow();
      }

    }

    allEvent.init();


    return { dataValue }
  }
}
</script>

<style scoped>
.a{ font-weight:bold; font-size: 25px; margin-bottom:10px; }
.b{ color:#333333; margin-bottom: 5px; }
.d{ margin-bottom: 20px; text-indent:2em; }
</style>