<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
  <div style="padding:15px;margin-bottom: 20px;" v-if="dataValue.loading === false">
    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>来源</th>
        <th>地址</th>
        <th>标题</th>
        <th>作者</th>
        <th>时间</th>
        <th>&nbsp;</th>
      </tr>
      <tr v-for="aRow in dataValue.list.output.news" :style="selectRowColor(aRow['newId'])"
          @click="selectRowEvent(aRow['newId'])">

        <td class="min">{{ aRow['newId'] }}</td>
        <td class="min">{{ aRow['source'] }}</td>
        <td>{{ aRow['src'] }}</td>
        <td>{{ aRow['title'] }}</td>
        <td>{{ aRow['from'] }}</td>
        <td class="min">{{ aRow['date'] }}</td>
        <td class="min"><a @click="allEvent.show.goOpen(aRow['newId']);">查看</a></td>
      </tr>
      <tr>
        <td colspan="7" class="pages">
          <APages :number_of_page="dataValue.list.input.numberOfPage"
                  :this_page="dataValue.list.input.page" :all_page="dataValue.list.output.allPage"
                  @toPage="allEvent.setPage"
          />
        </td>
      </tr>
    </table>

  </div>

  <a-drawer
      title="查看新闻详细"
      width="1050"
      :visible="dataValue.show.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      @close="allEvent.show.goClose" >
    <showPage :cnewid="dataValue.show.cnewid" />
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import showPage from "@/view/crawler_news/show"


export default {
  name : "crawler_news",
  emits : [ 'setMenuKey' ],
  components : { APages, showPage },
  setup(props, { emit }) {
    emit('setMenuKey', 'crawlerNews');

    let dataValue = reactive({

      loading : false,
      selectRow: null,

      list : {
        input : {
          page : 1,
          numberOfPage : 20
        },

        output : {
          allCount : 0,
          allPage : 0,
          news : []
        }
      },

      show :{
        visible : false,
        cnewid : 0
      }
    })

    // 选择其中一行
    const selectRowEvent = (rowId) => {
      dataValue.selectRow = rowId;
    }
    let selectRowColor = (rowId) => {
      return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
    }

    let allEvent = {

      show :{
        goOpen : (newid)=>{
          dataValue.show.cnewid = newid;
          dataValue.show.visible = true;
        },
        goClose : ()=>{
          dataValue.show.visible = false;
        }
      },

      listHttp : () => {
        dataValue.loading = true;
        httpRequest.post('/admin/crawler.news/list', dataValue.list.input)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.list.output.allCount = res.data['allCount'];
                dataValue.list.output.allPage = res.data['allPage'];
                dataValue.list.output.news = res.data['news'];
                dataValue.loading = false;
              }
            })
      },

      setPage : (pageId) => {
        dataValue.list.input.page = pageId;
        allEvent.listHttp();
        ;
      },

      init : () => {
        allEvent.listHttp();
      }
    }

    allEvent.init();

    return { dataValue, allEvent, selectRowEvent, selectRowColor }
  }


}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>